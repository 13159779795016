<template>
  <div>
    <c-button
        @click="openFilterModal"
        variant-color="vc-orange"
        variant="outline"
        right-icon="filter"
        size="sm"
        position="absolute"
        top="18"
        right="20"
    >
      Filter
    </c-button>

    <!-- Filter Dialog -->
    <c-modal
        :is-open="isFilterModalOpen"
        @close="closeFilterModal"
        :closeOnOverlayClick="false"
        size="lg"
        is-centered
        class="rounded-lg"
    >
      <c-modal-content>
        <!-- Modal Header -->
        <c-modal-header>Filter Options</c-modal-header>
        <c-modal-close-button @click="closeFilterModal" variant-color="pink" />
        <c-modal-body>
          <!-- Filter Checklist -->
          <c-form-control>
            <c-form-label>Select Filters</c-form-label>
            <c-checkbox-group v-model="selectedFilters">
              <c-checkbox class="dark:bg-pink-600" value="name">Name</c-checkbox>
              <c-checkbox variant-color="red" value="date">Date Range</c-checkbox>
              <c-checkbox variant-color="red" value="location">Location</c-checkbox>
            </c-checkbox-group>
          </c-form-control>

          <c-divider my="6" v-if="selectedFilters.length !== 0" color="gray"></c-divider>

          <!-- Filter by Name -->
          <c-form-control my="4" v-if="selectedFilters.includes('name')">
            <c-form-label>Filter by Name</c-form-label>
            <c-input placeholder="Enter Company Name" v-model="filterOptions.name" />
          </c-form-control>

          <!-- Date Range Filter -->
          <c-form-control my="4" v-if="selectedFilters.includes('date')">
            <c-form-label>Filter by Date Range</c-form-label>
            <c-stack direction="row" spacing="4">
              <c-input type="date" v-model="filterOptions.startDate" />
              <c-input type="date" v-model="filterOptions.endDate" />
            </c-stack>
          </c-form-control>

          <!-- Filter by Location -->
          <c-form-control my="4" v-if="selectedFilters.includes('location')">
            <c-form-label>Filter by Location</c-form-label>
            <c-input placeholder="Enter Location" v-model="filterOptions.location" />
          </c-form-control>
        </c-modal-body>

        <c-divider mt="7" color="gray"></c-divider>

        <!-- Modal Footer -->
        <c-modal-footer>
          <c-button variant-color="vc-orange" @click="applyFilters" mr="3">
            Apply
          </c-button>
          <c-button @click="clearFilters" variant="outline">
            Clear
          </c-button>
        </c-modal-footer>
      </c-modal-content>
    </c-modal>

    <div style="width: 100%">
      <c-heading as="h1">
        {{ criteria.title }}
      </c-heading>
      <c-text fontWeight="300" color="#495057" v-if="criteria.description">
        {{ criteria.description }}
      </c-text>
    </div>

    <c-simple-grid style="width: 100%" :columns="7" spacing-x="20px" mb="7" mt="10">
      <data-card
          subtitle="TOTAL"
          :title="'$' + totalCommission"
          cardBackgroundColor="rgba(66,103,178,0.44)"
          textColor="#4267B2"
      ></data-card>

      <data-card
          :title="'$' + monthlyCommission"
          :subtitle="'CURRENT MONTH'"
          cardBackgroundColor="rgba(0,181,105,0.29)"
          textColor="#00B569"
      ></data-card>

      <data-card
          subtitle="PENDING"
          :title="'$' + pendingCommission"
          cardBackgroundColor="rgba(193,53,132,0.26)"
          textColor="#C13584"
      ></data-card>
    </c-simple-grid>

    <div v-if="filteredInvoiceData !== null && filteredInvoiceData.length">
      <DataTable
          stripedRows
          responsiveLayout="stack"
          :value="filteredInvoiceData"
          class="table-body p-datatable-sm"
      >
        <template #header>
          <div style="display: flex; align-items: center; justify-content: space-between;">
            <span class="datatable-header-title">Earnings Details</span>
            <c-input
                v-model="searchQuery"
                placeholder="Search..."
                @input="onSearch"
                style="width: 250px; padding: 8px; margin-left: auto;"
            />
          </div>
        </template>
        <Column field="dateUsed" header="Date">
          <template #body="slotProps">
            <div>{{ fineDate(slotProps.data.dateUsed) }}</div>
          </template>
        </Column>
        <Column field="company.name" header="Business"></Column>
        <Column field="commissionPaymentStatus" header="Payment Status"></Column>
        <Column field="commissionAmount" header="Amount"></Column>
      </DataTable>
    </div>
    <c-flex flexDirection="column" v-else-if="filteredInvoiceData && filteredInvoiceData.length === 0" justify="center" align="center" w="100%" h="100%">
      <img style="z-index: 55" height="300px" width="300px" src="@/assets/img/empty.svg#empty-state"  alt="empty state"/>
      <c-text fontSize="15px" fontWeight="700" mt="50px">No coupon has been used yet</c-text>
    </c-flex>
    <c-flex h="500px" justify="center" align="center" v-else>
      <c-spinner
          thickness="4px"
          speed="0.65s"
          color="tomato"
          size="xl"
      />
    </c-flex>
  </div>
</template>

<script>
import DataCard from "@/views/Affiliate/components/DataCard.vue";
import DataTable from "primevue/datatable";
import { getAppliedCouponForAffiliate } from "@/services/company";
import { mapGetters } from "vuex";
import moment from "moment/moment";

export default {
  name: "Index",
  components: { DataTable, DataCard },
  computed: {
    ...mapGetters({
      company: "company/getActiveCompany",
    }),
  },

  methods: {
    onSearch() {
      const query = this.searchQuery.toLowerCase();
      this.filteredInvoiceData = this.invoiceData.filter((item) => {
        return (
            (item.dateUsed && item.dateUsed.toLowerCase().includes(query)) ||
            (item.company && item.company.name.toLowerCase().includes(query)) ||
            (item.commissionPaymentStatus && item.commissionPaymentStatus.toLowerCase().includes(query)) ||
            (item.commissionAmount && item.commissionAmount.toString().includes(query))
        );
      });
    },

    fineDate(dat) {
      return moment(dat).format("DD-MM-yyyy");
    },

    async getInvoiceData() {
      try {
        const response = await this.getAppliedCouponsData();
        if (response.success) {
          this.invoiceData = response.data;
          this.filteredInvoiceData = this.invoiceData; // Initialize filtered data
          this.applyFilters();
        } else {
          console.log("Something went wrong");
        }
      } catch (e) {
        console.log("Something went wrong. Please try again.");
      }
    },

    async getAppliedCouponsData() {
      try {
        const response = await getAppliedCouponForAffiliate(this.company.id, 1000000, 0);
        return { success: true, data: response.data.coupon_company };
      } catch (e) {
        return { success: false };
      }
    },

    openFilterModal() {
      this.isFilterModalOpen = true;
    },

    closeFilterModal() {
      this.isFilterModalOpen = false;
    },

    applyFilters() {
      this.filteredInvoiceData = this.invoiceData.filter((item) => {
        if (
            this.selectedFilters.includes("name") &&
            this.filterOptions.name &&
            !(item.company.name || "").toLowerCase().includes(this.filterOptions.name.toLowerCase())
        ) {
          return false;
        }

        if (this.selectedFilters.includes("date") && this.filterOptions.startDate && this.filterOptions.endDate) {
          const startDate = moment(this.filterOptions.startDate);
          const endDate = moment(this.filterOptions.endDate);
          const itemDate = moment(item.dateUsed);
          if (!itemDate.isBetween(startDate, endDate, "days", "[]")) {
            return false;
          }
        }

        if (
            this.selectedFilters.includes("location") &&
            this.filterOptions.location &&
            !(item.location || "").toLowerCase().includes(this.filterOptions.location.toLowerCase())
        ) {
          return false;
        }

        return true;
      });

      this.calculateDataCards();
      this.closeFilterModal();
    },

    clearFilters() {
      this.selectedFilters = [];
      this.filterOptions = {
        name: "",
        startDate: "",
        endDate: "",
        location: "",
      };
      this.filteredInvoiceData = this.invoiceData;
      this.calculateDataCards();
    },

    calculateDataCards() {
      const now = moment();
      this.totalCommission = this.filteredInvoiceData.reduce((acc, item) => acc + item.commissionAmount, 0);
      this.monthlyCommission = this.filteredInvoiceData
          .filter((item) => moment(item.dateUsed).isSame(now, "month"))
          .reduce((acc, item) => acc + item.commissionAmount, 0);
      this.pendingCommission = this.filteredInvoiceData
          .filter((item) => item.commissionPaymentStatus === "pending")
          .reduce((acc, item) => acc + item.commissionAmount, 0);
    },
  },

  mounted() {
    this.getInvoiceData();
  },

  data() {
    return {
      searchQuery: "",
      invoiceData: null,
      filteredInvoiceData: null,
      isFilterModalOpen: false,
      selectedFilters: [],
      filterOptions: {
        name: "",
        startDate: "",
        endDate: "",
        location: "",
      },
      criteria: {
        title: "Earnings",
        description:
            "Keep track of earnings made on each coupon, knowing what has been paid and what is left to be paid",
      },
      totalCommission: 0,
      monthlyCommission: 0,
      pendingCommission: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
}

.table-body {
  font-family: Montserrat;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
